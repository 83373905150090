import { FallbackProps } from 'react-error-boundary';
import { useTranslations } from 'next-intl';
import Link from 'next/link';

import { Button } from '@zealy/design-system';

import { OpenSupportButton } from '../open-support-button';

export interface ErrorFallbackProps extends FallbackProps {
  /**
   * Type of the error the user is experiencing.
   */
  meta?: string;
  title?: string;
  description?: string;
  customErrorMessage?: string;
  retryActionText?: string;
}

/**
 * Used to display an error message to the client in case something happens.
 * Use this component to wrap UI elements that are are prone to produce errors.
 * @example
 * ```tsx
 * // In parent component
 * import { ErrorBoundary } from "react-error-boundary"
 * import { ErrorFallback } from "#components/ErrorFallback"
 * import { ChildComponentWithPossibleError } from "#components/ChildComponentWithPossibleError"
 *
 * function ParentComponentWithErrorProneChild() {
 *   return (
 *      <ErrorBoundary FallbackComponent={ErrorFallback}>
 *        <ChildComponentWithPossibleError />
 *      </ErrorBoundary>
 *   )
 * }
 *
 * // In child component
 * import { useEffect } from "react"
 * import { useErrorBoundary } from "react-error-boundary"
 *
 * function ChildComponentWithPossibleError() {
 *    const { showBoundary } = useErrorBoundary()
 *
 *    async function possibleFailAction () {
 *      try {
 *        throw new Error("Catch me if you can :p")
 *      } catch (e: Error) {
 *        // Calling this will render the ErrorBoundary component.
 *        showBoundary(error)
 *      }
 *    }
 *
 *    useEffect(() => {
 *      possibleFailAction()
 *    }, [])
 * }
 * ```
 */
export function ErrorFallback(props: ErrorFallbackProps) {
  const t = useTranslations('error-boundary');

  const {
    error,
    customErrorMessage = t('description'),
    meta = t('subtitle'),
    title = t('title'),
    retryActionText = t('try-again'),
    resetErrorBoundary,
    ...rest
  } = props;

  return (
    <main className="px-150 md:px-300 mx-auto h-full max-w-[1400px]">
      <div className="h-4/5 grid justify-items-start content-center max-w-[546px]" {...rest}>
        <h4 className="section-heading text-brand-primary mb-200">{meta}</h4>
        <h1 className="heading-1 text-primary mb-300">{title}</h1>
        <p className="text-secondary body-lg mb-200">
          {customErrorMessage || error.toString?.() || error.message}
        </p>
        <div className="flex items-center mt-500 gap-200">
          <Button as={Link} href="/">
            {t('return-home')}
          </Button>
          {resetErrorBoundary && <Button onClick={resetErrorBoundary}>{retryActionText}</Button>}
          <OpenSupportButton title={t('contact-support')} />
        </div>
      </div>
    </main>
  );
}
